import { Box, TextField, Typography, IconButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "./accordion";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { baseURL } from "../../../../config";
import { hasHttps } from "../../../../utils/checkImageUrl";

const Faq = ({ applicationResources }) => {
  // Sorting the faqData array by order_sequence
  const sortedFaq = applicationResources?.data?.faq?.sort(
    (a, b) => a.order_sequence - b.order_sequence
  );

  const applicationFaq = sortedFaq
    ?.map((value) => {
      return {
        category: {
          title: value.category.title,
          id: value.category.id,
          icon: value.category.icon,
          is_active: value.category.is_active,
          items: sortedFaq
            ?.filter((item) => {
              return item.category.id === value.category.id;
            })
            .map((item) => {
              return {
                header: item.question_text,
                children: <ChildContainer text={item.answer_text} />,
              };
            }),
        },
      };
    })
    ?.filter((obj, index, arr) => {
      const categoryId = obj.category.id;
      return arr.findIndex((item) => item.category.id === categoryId) === index;
    });

  const [serchItem, setSearchItem] = useState(applicationFaq);

  const handleKeyword = (e) => {
    const inputValue = e.target.value.toLowerCase();
    const newValues = [...applicationFaq];
    const filterFaq = newValues.map((category) => {
      const filterItems = category.category.items?.filter((item) =>
        item?.header?.toLowerCase()?.includes(inputValue)
      );
      return { category: { ...category?.category, items: filterItems } };
    });

    const filterWithItems = filterFaq.filter(
      (category) => category?.category?.items.length > 0
    );

    setSearchItem(filterWithItems);
  };

  return (
    <div>
      {/* search */}
      <Box mt={4}>
        <TextField
          onChange={handleKeyword}
          type="search"
          variant="outlined"
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ fill: "#9309FE" }} />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "8px 8px 8px 0px ",
            },
            "& .MuiInputBase-input": {
              padding: "4px 0px", // Adjust the padding values as needed
            },
          }}
        />
      </Box>
      <Box
        sx={{
          maxHeight: "58vh",
          mt: 3,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {serchItem?.map((item, index) => {
          return (
            item?.category?.is_active && <FaqSection key={index} item={item} />
          );
        })}
      </Box>
    </div>
  );
};
export default Faq;

const FaqSection = ({ item }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow((prev) => !prev);
  };

  const showIcon = (
    <IconButton>
      <ExpandLessIcon sx={{ height: 35, width: 35 }} />
    </IconButton>
  );
  const hideIcon = (
    <IconButton>
      <ExpandMoreIcon sx={{ height: 35, width: 35 }} />
    </IconButton>
  );

  return (
    <Box>
      <Box
        onClick={handleShow}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 3,
          cursor: "pointer",
        }}
      >
        <img
          height={30}
          width={30}
          src={
            hasHttps(item.category.icon)
              ? item.category.icon
              : `${baseURL}${item.category.icon ?? ""}`
          }
          alt=""
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 3,
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", textWrap: "wrap", whiteSpace: "normal" }}
          >
            {item.category.title}
          </Typography>
          {show ? showIcon : hideIcon}
        </Box>
      </Box>
      {show && <Accordion margin={1} accordionItem={item.category.items} />}
    </Box>
  );
};

const ChildContainer = ({ text }) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: "13px",
          fontFamily: "unset",
          fontWeight: "300",
          mb: 2,
          mt: 1,
          whiteSpace: "normal",
        }}
      >
        {/* {text} */}
        <div
          style={{
            fontFamily: "inherit",
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>

        {/* <HTMLRenderer src={text} /> */}
      </Typography>
    </Box>
  );
};
