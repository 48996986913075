import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import Toaster from "./Toaster";
import ApplicationLayout from "./components/presentation/containers/ApplicationLayout";
import MainLayout from "./components/presentation/containers/MainLayout";
import { AnimatePresence } from "framer-motion";

import privateRoutes from "./routes/privateRoutes";
import sessionRoutes from "./routes/sessionRoutes";
import { getNotifications } from "./actions/global";
import TermsAndCondition from "./utils/TermsAndCondition";
import { sendConsent } from "./actions/auth";
import PageNotFound from "./pages/pageNotFound";
import { LinearProgress } from "@mui/material";
import Snackbar from "./components/presentation/common/snackbar";
import Transitions from "./utils/transition";
import { FAVICON } from "./config";

export default function App() {
  const { isAuthenticated, loggedUserData } = useSelector(
    (store) => store.auth
  );
  const applicationCountFromSession =
    sessionStorage.getItem("application_count");
  const showFirstTimeLoginFlow = applicationCountFromSession == 0;

  const dispatch = useDispatch();
  const userDetails = JSON.parse(sessionStorage.getItem("loggedUserData"));

  // useEffect(() => {
  //   isAuthenticated &&
  //     dispatch(getNotifications(loggedUserData?.user_data?.invoffuserid));
  // }, [isAuthenticated, loggedUserData?.user_data?.invoffuserid]);

  const partyLogo =
    userDetails?.user_data?.party_business?.company_logo?.document ?? FAVICON;
  const partyName = userDetails?.user_data?.party_business?.name;

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
    link.href = partyLogo;
    document.title = partyName ?? "BareRock Underwriting Limited";
  }, [partyLogo, partyName]);

  const [openPolicyModal, setOpenPolicyModal] = useState(true);
  const policyDocument = userDetails?.user_data.consents?.document?.document;
  const policyDocumentName = userDetails?.user_data.consents?.document?.name;
  const handlePolicyAgree = () => {
    const payload = {
      consent_type: "data_privacy_policy",
      consent_status: true,
      file_path: policyDocument,
    };
    dispatch(sendConsent(payload, setOpenPolicyModal));
  };
  const isConcern = sessionStorage.getItem("consent");

  return (
    <>
      <Toaster />
      <Snackbar />
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {isConcern === "false" && isAuthenticated && (
            <TermsAndCondition
              title="Privacy Notice"
              policyDocumentName={policyDocumentName}
              open={openPolicyModal}
              setOpen={setOpenPolicyModal}
              documentFile={policyDocument}
              handlePolicyAgree={handlePolicyAgree}
            />
          )}
          <AnimatePresence>
            <Switch>
              {privateRoutes.map((route, key) => {
                const { component, path } = route;
                return (
                  component !== undefined && (
                    <Route
                      exact
                      path={path}
                      key={key}
                      render={(route) =>
                        isAuthenticated ? (
                          <>
                            {showFirstTimeLoginFlow != null ? (
                              <>
                                {!showFirstTimeLoginFlow ? (
                                  <MainLayout>
                                    <Transitions>
                                      <Route
                                        path={path}
                                        exact
                                        component={component}
                                      />
                                    </Transitions>
                                  </MainLayout>
                                ) : (
                                  <ApplicationLayout>
                                    <Transitions>
                                      <Route
                                        path={path}
                                        exact
                                        component={component}
                                      />
                                    </Transitions>
                                  </ApplicationLayout>
                                )}
                              </>
                            ) : (
                              <LinearProgress color="primary" />
                            )}
                          </>
                        ) : (
                          <Redirect to="/login" />
                        )
                      }
                    />
                  )
                );
              })}
              {sessionRoutes.map((route, key) => {
                const { component, path } = route;
                return (
                  <Route
                    exact
                    path={path}
                    key={key}
                    render={(route) =>
                      isAuthenticated ? (
                        <Redirect to="/home" />
                      ) : (
                        <Route path={path} exact component={component} />
                      )
                    }
                  />
                );
              })}
              <Route path="*" component={PageNotFound} />
            </Switch>
          </AnimatePresence>
        </ThemeProvider>
      </Router>
    </>
  );
}
