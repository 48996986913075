import {
  showErrorMessage,
  showSuccessMessage,
} from "../components/presentation/common/snackbar";
import { InsuranceUrl, InsurecoreAPI } from "../config";
import { errorMessage, successMessage } from "../utils/responseMessage";

export const getAllPolicy = (companyId) => (dispatch) => {
  dispatch({
    type: "GET_ALL_POLICY_LOADING",
    payload: true,
  });
  InsurecoreAPI.get(`/customer/policies?company_id=${companyId}`)
    .then((res) => {
      dispatch({
        type: "GET_ALL_POLICY",
        payload: res.data,
      });
      dispatch({
        type: "GET_ALL_POLICY_LOADING",
        payload: false,
      });
    })
    .catch((error) => {
      showErrorMessage(errorMessage(error));
      dispatch({
        type: "GET_ALL_POLICY_LOADING",
        payload: false,
      });
    });
};

export const getSinglePolicy = (id) => (dispatch) => {
  dispatch({
    type: "SINGLE_POLICY_LOADING",
    payload: true,
  });
  InsurecoreAPI.get(`/customer/policies/${id}?details=full`)
    .then((res) => {
      dispatch({
        type: "GET_SINGLE_POLICY",
        payload: res.data,
      });
      dispatch({
        type: "SINGLE_POLICY_LOADING",
        payload: false,
      });
    })
    .catch((error) => {
      showErrorMessage(errorMessage(error));
      dispatch({
        type: "SINGLE_POLICY_LOADING",
        payload: false,
      });
    });
};

export const getProducts = (partyBusinessId) => (dispatch) => {
  InsuranceUrl.get(`/products?party_business_id=${partyBusinessId}`)
    .then((res) => {
      dispatch({
        type: "GET_PRODUCT",
        payload: res.data,
      });
    })
    .catch((error) => {
      showErrorMessage(errorMessage(error));
    });
};

export const getRelatedProductType = () => (dispatch) => {
  InsuranceUrl.get(`ref-data/group-product/`)
    .then((res) => {
      dispatch({
        type: "GET_RELATED_PRODUCT_TYPE",
        payload: res.data,
      });
    })
    .catch((error) => {
      showErrorMessage(errorMessage(error));
    });
};

export const getAdvisor =
  ({ policyId }) =>
  (dispatch) => {
    try {
      InsurecoreAPI.get(
        `/applications/filter?policy_master_id=${policyId}&group_name=tr_application_individuals`
      ).then((res) => {
        dispatch({
          type: "GET_ADVISOR_LIST",
          payload: res.data,
        });
      });
    } catch (error) {
      showErrorMessage(errorMessage(error));
    }
  };

export const getRefMta = () => (dispatch) => {
  InsuranceUrl.get(`ref-data/mta/`)
    .then((res) => {
      dispatch({
        type: "GET_REF_MTA",
        payload: res.data,
      });
    })
    .catch((error) => showErrorMessage(errorMessage(error)));
};

export const getMtaQuestion =
  ({ policyId, mtaId }) =>
  (dispatch) => {
    const loading = (payload) =>
      dispatch({
        type: "GET_MTA_QUESTION_LOADING",
        payload,
      });
    loading(true);
    InsurecoreAPI.get(`policies/${policyId}/mta-questions?mta_id=${mtaId}`)
      .then((res) => {
        dispatch({
          type: "GET_MTA_QUESTION",
          payload: res?.data,
        });
        loading(false);
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
        loading(false);
      });
  };

export const createMta =
  ({ policyId, payload, createdBy, selectedFile, setShowFinalScreen }) =>
  (dispatch) => {
    const loading = (payload) =>
      dispatch({
        type: "CREATE_MTA_LOADING",
        payload,
      });

    loading(true);
    const mtaId = payload?.mta_type_id;
    InsurecoreAPI.post(`policies/${policyId}/mta`, payload)
      .then((res) => {
        showSuccessMessage(successMessage(res));
        if (selectedFile?.length > 0) {
          const payloadData = new FormData();
          payloadData.append("mta_id", mtaId);
          payloadData.append("created_by", createdBy);
          for (let i = 0; i < selectedFile.length; i++) {
            payloadData.append("documents[]", selectedFile[i]);
          }
          InsurecoreAPI.post(`policies/${policyId}/documents`, payloadData);
        }
        setShowFinalScreen?.(true);
        loading(false);
        dispatch({
          type: "SET_SHOW_CONFIRMATION",
          payload: true,
        });
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
        loading(false);
      });
  };

export const acceptMtaChanges =
  ({ policyId, mtaId, setIsConfirm }) =>
  (dispatch) => {
    const loading = (payload) =>
      dispatch({
        type: "ACCEPT_MTA_CHANGES_LOADING",
        payload,
      });

    loading(true);
    InsurecoreAPI.post(`policies/${policyId}/mta/${mtaId}/accept`)
      .then((res) => {
        showSuccessMessage(successMessage(res));
        dispatch({
          type: "ACCEPT_MTA_CHANGES",
          payload: res.data,
        });
        loading(false);
        setIsConfirm(true);
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
        loading(false);
      });
  };

export const getMtaAnswers =
  ({ policyId, mtaId }) =>
  (dispatch) => {
    const loading = (payload) =>
      dispatch({
        type: "GET_MTA_ANSWERS_LOADING",
        payload,
      });
    loading(true);
    InsurecoreAPI.get(`policies/${policyId}/mta-answers/${mtaId}`)
      .then((res) => {
        dispatch({
          type: "GET_MTA_ANSWERS",
          payload: res.data,
        });
        loading(true);
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
        loading(false);
      });
  };
