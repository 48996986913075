import { acceptMtaChanges } from "../actions/policy";

const initialState = {
  allPolicyLoading: false,
  singlePolicyLoading: false,
  createMtaLoading: false,
  showConfirmation: false,
  mtaAnswersLoading: false,
  acceptMtaChangesLoading: false,
  allPolicy: [],
  singlePolicy: {},
  products: {},
  lastYearProduct: [],
  advisorList: [],
  futureProduct: [],
  relatedProductType: [],
  refMta: [],
  mtaQuestion: [],
  mtaQuestionLoading: false,
};

const policyReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case "GET_ALL_POLICY_LOADING":
      return {
        ...state,
        allPolicyLoading: payload,
      };
    case "GET_ALL_POLICY":
      return {
        ...state,
        allPolicy: payload,
      };
    case "GET_REF_MTA":
      return {
        ...state,
        refMta: payload,
      };
    case "SET_SHOW_CONFIRMATION":
      return {
        ...state,
        showConfirmation: payload,
      };
    case "GET_MTA_ANSWERS":
      return {
        ...state,
        mtaAnswers: payload,
      };
    case "GET_MTA_ANSWERS_LOADING":
      return {
        ...state,
        mtaAnswersLoading: payload,
      };
    case "ACCEPT_MTA_CHANGES_LOADING":
      return {
        ...state,
        acceptMtaChangesLoading: payload,
      };
    case "GET_MTA_QUESTION":
      return {
        ...state,
        mtaQuestion: payload,
      };
    case "GET_MTA_QUESTION_LOADING":
      return {
        ...state,
        mtaQuestionLoading: payload,
      };
    case "SINGLE_POLICY_LOADING":
      return {
        ...state,
        singlePolicyLoading: payload,
      };
    case "CREATE_MTA_LOADING":
      return {
        ...state,
        createMtaLoading: payload,
      };
    case "GET_SINGLE_POLICY":
      return {
        ...state,
        singlePolicy: payload,
      };
    case "GET_RELATED_PRODUCT_TYPE":
      return {
        ...state,
        relatedProductType: payload,
      };

    case "GET_ADVISOR_LIST":
      return {
        ...state,
        advisorList: payload,
      };
    case "GET_PRODUCT":
      return {
        ...state,
        products: payload,
      };
    default:
      return state;
  }
};

export default policyReducer;
