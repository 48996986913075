import { lazy } from "react";
import Loadable from "./Loadable";

const Login = Loadable(lazy(() => import("../pages/Account/Login")));
const resetPassword = Loadable(
  lazy(() => import("../pages/Account/ResetPassword"))
);
const changePassword = Loadable(
  lazy(() => import("../pages/Account/ForgotPassword"))
);
const ResetEmailCheck = Loadable(
  lazy(() => import("../pages/Account/resetEmailCheck"))
);
const userSetPassword = Loadable(
  lazy(() => import("../pages/Account/UserSetPassword"))
);

const sessionRoutes = [
  {
    component: Login,
    path: "/",
  },

  {
    component: Login,
    path: "/login",
  },
  {
    component: resetPassword,
    path: "/reset-password",
  },
  {
    component: changePassword,
    path: "/forgot-password",
  },
  {
    component: ResetEmailCheck,
    path: "/email-check",
  },
  {
    component: userSetPassword,
    path: `/user-set-password`,
  },
];
export default sessionRoutes;
